.search-input {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2.4rem;
    border: 1px solid #e6e6e6;
    font-size: 1.6rem;

    :focus {
        border: 0;
    }

    svg {
        margin: 0 1.2rem;
    }

    input {
        border: 0;
        margin-bottom: 0;
        padding-left: 0;
        color: $grey-4;
    }

    ::-webkit-input-placeholder {
        color: $grey-2;
    }
    ::-moz-placeholder { 
        color: $grey-2;
    }
    :-ms-input-placeholder { 
        color: $grey-2;
    }
    :-moz-placeholder { 
        color: $grey-2;
    }

    &__clear {
        padding: 1.2rem;
        color: $grey-3;
        cursor: pointer;
    }
}

.module-list {
    &__categories {
        ul {
            padding: 0;

            li {
                list-style: none;
                padding: 0.6rem 0;
                cursor: pointer;

                &.selected {
                    font-weight: bold;
                }
            }
        }

        h2 {
            padding: 1.8rem 0;
            margin-bottom: 1rem;
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
    }

    &__sort {
        display: flex;
        justify-content: flex-start;

        & > div {
            flex-basis: 20rem;
        }

        select {
            padding-left: 0;
        }
    }

    &__categories {
        h3 {
            margin: 1.6rem 0;
        }
    }
}

.module-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.2rem;
    width: 100%;

    &__icon {
        display: flex;
        width: 100%;

        img {
            width: 3.6rem;
            height: auto;
        }

        a {
            padding-left: 1.2rem;
            flex: 1;
        }
    }   

    &__content {
        display: block;
        padding: 0 0 2.4rem;

        & > div:first-child {
            display: flex;
            width: 100%;
        }

        .thumbnail {
            flex: 0 0 8rem;
            margin-right: 2rem;
        }

        .description {
            flex: 1;
            padding-top: 1rem;

            &__top {
                display: flex;
                margin-bottom: 1rem;
            }

            &__bottom {
                display: block;
                width: 100%;

                & > div:first-child {
                    flex: 1;
                    align-self: center;
                }

                
                & > div:last-child {
                    margin-top: 1.2rem;
                }
            }

            div > span {
                margin: 0;
                padding-right: 1.2rem;
                font-size: 1.2rem;
                line-height: 1;
                color: $grey-3;
                white-space: nowrap;

                a {
                    color: $grey-3;
        
                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }
            }

            .rating-container {
                display: inline-block;
            }
        }

        img {
            width: 100%;
            max-width: 8rem;
            height: auto;
            max-height: 8rem;
        }
    }

    &__title {
        display: none;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;

        &--mobile {
            display: flex;
            margin-left: 1.2rem;
            flex: 1;
        }

        div {
            display: flex;
            align-items: center;
            padding: 1.1rem 0 1rem;
        }
        
        h3 {
            margin: 0;
        }

        .verified {
            margin-left: 1rem;
        }
    }

    .download {
        width: 100%;

        .btn {
            width: 100%;
        }
    }

    &__categories {
        display: inline-block;

        svg {
            margin-right: 0.6rem;
        }
    }

    p {
        margin: 0;
        color: $grey-5;
    }    
}

@media only screen and (min-width : $mobile) {
    .module-block {
        flex-direction: row;

        &__icon {
            display: block;
            width: 4.8rem;

            img {
                width: 100%;
            }
        }   

        &__title {
            display: flex;

            &--mobile {
                display: none;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 4.8rem 0 1.8rem;
        }
    }
}

@media only screen and (min-width : $tablet-sm) {

    .module-block {
        &__icon {
            width: 6rem;
        }   

        &__content {
            & > div:first-child {
                width: 100%;
            }

            .download {
                width: auto;
            }
        }
    }

    .module-list {
        &__categories {
            padding: 11rem 2.4rem 0 0;
        }

        .two-col {
            display: flex;
            flex-direction: row;

            & > div{
                flex: 1;
    
                &:first-child {
                    flex: 0 0 25%;
                }
            }
        }  
        
        &__sort {
            justify-content: flex-end;

            select {
                padding-left: 1.2rem;
            }
        }  
    } 
}

@media only screen and (min-width : $tablet-lg) {

    .module-block {

        .description  {

            &__bottom {
                display: flex;

                & > div:first-child {
                    flex: 1;
                    align-self: center;
                }
                & > div:last-child {
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (min-width : $desktop-sm) {

    .module-block {
        &__content {
            flex-wrap: nowrap;

            & > div:first-child {
                flex: 1;
            }

            .download {
                margin-top: 0;
                margin-left: 2.4rem;
            }
        }
    }
}