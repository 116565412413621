.comments,
.comment-form {
    .container {
        max-width: 76.8rem;
    }
}

.comments {
    .container {
        padding-top: 0;
    }
    ul {
        padding: 0;

        li {
            display: block;
        }
    }
}

.comment {
    position: relative;
    display: flex;
    margin: 1.2rem 0;

    &__author {
        margin-right: 2rem;
    }

    &__content {
        position: relative;
        background-color: $grey-1;
        flex: 1;
        padding: 2.4rem;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            left: -1.5rem;
            border-style: solid;
            border-width: 0 1.6rem 1.6rem 0;
            border-color: transparent $grey-1 transparent transparent;
        }

        & > div:first-child > *:first-child {
            margin-top: 0;
        }

        span {
            display: block;
            color: $grey-3;
            font-size: 1.2rem;
        }
    }

    &--reply {
        margin-left: 7.2rem;
    }
}

.comment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -4.8rem;
    background-color: $grey-1;

    .container {
        padding: 7.2rem;
    }

    label {
        display: block;
    }

    input,
    textarea {
        width: 100%;
    }

    .btn {
        float: right;
        width: auto;
        margin-bottom: 0;
    }

    form {
        padding-top: 2.4rem;
    }

    &__success {
        color: $success;
    }

    &__error {
        color: $error;
    }
}

.star {
    &--empty {
        color: $grey-2;
    }
    &--personal {
        color: $cw-navy;
    }
    &--full {
        color: $cw-gold;
    }
}

@media only screen and (min-width : $tablet-lg) {

    .comment-form {
        .two-col {
            div:first-child {
                padding-right: 0.6rem;
            }
            div:last-child {
                padding-left: 0.6rem;
            }
        }
    }
}
