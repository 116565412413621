.media {
    &--gallery {
        margin: 4.8rem 0;

        .slick-list div {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 25rem;
        }
    
        .slick-prev:before, 
        .slick-next:before {
            color: black;
        }
    
        .slick-slide {
    
            img {
                max-width: 100%;
                max-height: 25rem;
            }
    
            div {
                position: relative;
                
                &:hover {
                    .expand-container {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
            }
    
            .expand-container {
                position: absolute;
                top: 0;
                right: 0;
                width: 4rem;
                height: 4rem;
                background: rgba($grey-3, 0.5);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
                cursor: pointer;
            }
    
            .expand-icon {
                position: absolute;
                width: 2rem;
                height: 2rem;
                top: 1rem;
                right: 1rem;
                color: #ffffff;
            }
        }
    }

    &--video {
        .video {
            &__youtube {
                iframe {
                    width: 100%;
                }
            }
            
            &__upload {
                outline: 0;
            }
        }
    }
}

@media only screen and (min-width : $tablet-lg) {
    .media {
        &--gallery {
            flex: 0 0 50% !important;
            margin: 0;
        }

        &--video {
            flex: 0 0 65% !important;
        }
    }
}