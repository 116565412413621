@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,900&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700&display=swap');
@import "slick";
@import "slickTheme";
@import "~react-image-lightbox/style.css";

@import "variables";
@import "general";
@import "partials";
@import "module-list";
@import "module-details";
@import "comments";
@import "media";
@import "pagination";
