.page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 13rem;
    background-color: $grey-1;

    .container {
        position: relative;
    }

    &__back-link {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5rem;
        height: 2rem;

        img {
            width: auto;
            height: 100%;
        }
    }

    h1 {
        margin: 0;
        &.with-link {
            padding-left: 5rem;
        }
    }
}

.dropdown {
    border: 0;
    font-size: 1.4rem;
    color: $grey-3;
    cursor: pointer;

    &--mobile {
        display: block;
        padding: 1.2rem;
    }

    &--desktop {
        display: none;
        outline: none;

        .select {

            &__menu {
                margin-top: 0;
                border-radius: 0;
            }

            &__menu-list {
                padding: 0;
            }

            &__option:active {
                background-color: $grey-2;
            }
        
            &__option--is-focused {
                background-color: $grey-1;
            }

            &__option--is-selected {
                background-color: $grey-2;
            }

            &__control {
                border: 0;
                box-shadow: none;
            }

            &__indicator-separator {
                display: none;
            }

            &__single-value {
                color: inherit;
                right: 10px;
            }
        }
    }

    & > div {
        cursor: pointer;
    }
}

.checkbox {
    position: relative;
    margin: 1.2rem 0;
}

.checkbox input[type="checkbox"] {
    position: absolute;
    margin: 0;
    height: 100%;
    opacity: 0;
}

.checkbox label {
    position: relative;
    display: inline-block;
    padding: 0;
    padding-left: 2.4rem;
    top: 0;
    z-index: -1;

    &:before,
    &:after {
        position: absolute;
        content: '';
        display: inline-block;
    }
}

.checkbox label::before{
    height: 16px;
    width: 16px;
    border: 1px solid $grey-3;
    left: 0px;
    top: 3px;
}

.checkbox label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid $grey-3;
    border-bottom: 2px solid $grey-3;
    transform: rotate(-45deg);
    left: 4px;
    top: 7px;
}

.checkbox input[type="checkbox"] + label::after {
    content: none;
}

.checkbox input[type="checkbox"]:checked + label::after {
    content: "";
}


@media only screen and (min-width : $tablet-sm) {

    .dropdown {
        &--mobile {
            display: none;
        }
        &--desktop {
            display: block;
        }
    }
}


@media only screen and (min-width : $tablet-lg) {

    .page-title {
        h1.with-link {
            padding: 0;
        }
    }
}