.module-details {
    .two-col {
        padding-bottom: 4.8rem;
    }

    &__long-desc {
        padding-top: 4.8rem;
    }

    .details {
        flex: 1 1 auto;
        padding: 0 0 2.4rem 0;

        & > div:first-child {
            display: flex;
            align-items: center;
        }

        &__icon {
            margin-right: 1.2rem;
            max-width: 3.2rem;
            height: auto;
        }
        
        p {
            margin: 0.4rem 0;
            font-size: 1.4rem;
        }
    }

    .download {
        margin-top: 2.4rem;
    }
}

@media only screen and (min-width : $tablet-lg) {
    .module-details {
        .details {
            padding: 0 4.8rem 0 0;
        }
    }
}