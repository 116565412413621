@import url(https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700,900&display=swap);
@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 1.6rem;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

* {
  box-sizing: border-box;
  min-height: 0;
  min-width: 0; }

html,
body,
body > div {
  height: 100%; }

html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #323232; }

a {
  text-decoration: none; }

h1 {
  font-size: 3.2rem;
  font-weight: 300;
  color: #5c5e5f; }

h2 {
  margin: 0 0 2.4rem;
  font-size: 2rem;
  font-weight: bold; }

h3 {
  font-size: 1.5rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #323232; }

hr {
  margin: 2.4rem -2.4rem;
  border: 0;
  border-bottom: 2px solid #f6f6f6; }

select,
input,
textarea {
  font-family: "Lato", sans-serif;
  font-size: 1.6rem; }
  select:focus,
  input:focus,
  textarea:focus {
    outline: none; }

input,
textarea {
  display: block;
  width: 100%;
  margin-bottom: 1.2rem;
  padding: 1.2rem;
  border: 1px solid #e6e6e6; }

label {
  padding-bottom: 0.8rem; }

header {
  position: relative;
  box-shadow: 5px 5px 15px -5px #cccccc;
  z-index: 1;
  max-height: 10rem; }
  header > div {
    margin: auto;
    padding: 1.2rem 2.4rem;
    width: 100%;
    max-width: 120rem; }
    header > div div {
      display: inline-block; }
  header img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 7.2rem; }

main {
  position: relative;
  min-height: calc(100% - 10rem);
  margin-bottom: -4.2rem; }

footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 4.2rem;
  padding: 1rem;
  background-color: #f6f6f6;
  font-size: 1.2rem; }

svg {
  color: #cccccc; }

.container {
  position: relative;
  display: block;
  margin: auto;
  padding: 2.4rem;
  width: 100%;
  max-width: 120rem; }

section {
  padding: 2.4rem 4.8rem; }
  section .overview {
    padding-bottom: 4.8rem; }

.verified {
  margin: 0 0.6rem;
  width: 1.5rem !important;
  height: 1.5rem !important; }

.btn {
  display: inline-block;
  margin-bottom: 1.2rem;
  padding: 1.2rem 2.4rem;
  width: auto;
  background-color: #002d72;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  cursor: pointer; }
  .btn:hover {
    background-color: #002967; }
  .btn:focus {
    background-color: #00245b; }
  .btn--2 {
    background-color: #f6f6f6;
    color: #323232; }
    .btn--2:hover {
      background-color: #f2f2f2; }
    .btn--2:focus {
      background-color: #e4e4e4; }

.two-col {
  display: block; }

.idealab {
  position: relative; }

@media only screen and (min-width: 768px) {
  .container {
    padding: 4.8rem 10.8rem; }
  .two-col {
    display: flex;
    flex-direction: row; }
    .two-col > div {
      flex: 1 1; } }

.page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 13rem;
  background-color: #f6f6f6; }
  .page-title .container {
    position: relative; }
  .page-title__back-link {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 5rem;
    height: 2rem; }
    .page-title__back-link img {
      width: auto;
      height: 100%; }
  .page-title h1 {
    margin: 0; }
    .page-title h1.with-link {
      padding-left: 5rem; }

.dropdown {
  border: 0;
  font-size: 1.4rem;
  color: #939393;
  cursor: pointer; }
  .dropdown--mobile {
    display: block;
    padding: 1.2rem; }
  .dropdown--desktop {
    display: none;
    outline: none; }
    .dropdown--desktop .select__menu {
      margin-top: 0;
      border-radius: 0; }
    .dropdown--desktop .select__menu-list {
      padding: 0; }
    .dropdown--desktop .select__option:active {
      background-color: #cccccc; }
    .dropdown--desktop .select__option--is-focused {
      background-color: #f6f6f6; }
    .dropdown--desktop .select__option--is-selected {
      background-color: #cccccc; }
    .dropdown--desktop .select__control {
      border: 0;
      box-shadow: none; }
    .dropdown--desktop .select__indicator-separator {
      display: none; }
    .dropdown--desktop .select__single-value {
      color: inherit;
      right: 10px; }
  .dropdown > div {
    cursor: pointer; }

.checkbox {
  position: relative;
  margin: 1.2rem 0; }

.checkbox input[type="checkbox"] {
  position: absolute;
  margin: 0;
  height: 100%;
  opacity: 0; }

.checkbox label {
  position: relative;
  display: inline-block;
  padding: 0;
  padding-left: 2.4rem;
  top: 0;
  z-index: -1; }
  .checkbox label:before, .checkbox label:after {
    position: absolute;
    content: '';
    display: inline-block; }

.checkbox label::before {
  height: 16px;
  width: 16px;
  border: 1px solid #939393;
  left: 0px;
  top: 3px; }

.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid #939393;
  border-bottom: 2px solid #939393;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 4px;
  top: 7px; }

.checkbox input[type="checkbox"] + label::after {
  content: none; }

.checkbox input[type="checkbox"]:checked + label::after {
  content: ""; }

@media only screen and (min-width: 600px) {
  .dropdown--mobile {
    display: none; }
  .dropdown--desktop {
    display: block; } }

@media only screen and (min-width: 768px) {
  .page-title h1.with-link {
    padding: 0; } }

.search-input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2.4rem;
  border: 1px solid #e6e6e6;
  font-size: 1.6rem; }
  .search-input :focus {
    border: 0; }
  .search-input svg {
    margin: 0 1.2rem; }
  .search-input input {
    border: 0;
    margin-bottom: 0;
    padding-left: 0;
    color: #5c5e5f; }
  .search-input ::-webkit-input-placeholder {
    color: #cccccc; }
  .search-input ::-moz-placeholder {
    color: #cccccc; }
  .search-input :-ms-input-placeholder {
    color: #cccccc; }
  .search-input :-moz-placeholder {
    color: #cccccc; }
  .search-input__clear {
    padding: 1.2rem;
    color: #939393;
    cursor: pointer; }

.module-list__categories ul {
  padding: 0; }
  .module-list__categories ul li {
    list-style: none;
    padding: 0.6rem 0;
    cursor: pointer; }
    .module-list__categories ul li.selected {
      font-weight: bold; }

.module-list__categories h2 {
  padding: 1.8rem 0;
  margin-bottom: 1rem; }

.module-list__pagination {
  display: flex;
  justify-content: center; }

.module-list__sort {
  display: flex;
  justify-content: flex-start; }
  .module-list__sort > div {
    flex-basis: 20rem; }
  .module-list__sort select {
    padding-left: 0; }

.module-list__categories h3 {
  margin: 1.6rem 0; }

.module-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 7.2rem;
  width: 100%; }
  .module-block__icon {
    display: flex;
    width: 100%; }
    .module-block__icon img {
      width: 3.6rem;
      height: auto; }
    .module-block__icon a {
      padding-left: 1.2rem;
      flex: 1 1; }
  .module-block__content {
    display: block;
    padding: 0 0 2.4rem; }
    .module-block__content > div:first-child {
      display: flex;
      width: 100%; }
    .module-block__content .thumbnail {
      flex: 0 0 8rem;
      margin-right: 2rem; }
    .module-block__content .description {
      flex: 1 1;
      padding-top: 1rem; }
      .module-block__content .description__top {
        display: flex;
        margin-bottom: 1rem; }
      .module-block__content .description__bottom {
        display: block;
        width: 100%; }
        .module-block__content .description__bottom > div:first-child {
          flex: 1 1;
          align-self: center; }
        .module-block__content .description__bottom > div:last-child {
          margin-top: 1.2rem; }
      .module-block__content .description div > span {
        margin: 0;
        padding-right: 1.2rem;
        font-size: 1.2rem;
        line-height: 1;
        color: #939393;
        white-space: nowrap; }
        .module-block__content .description div > span a {
          color: #939393; }
          .module-block__content .description div > span a:hover {
            text-decoration: underline; }
        .module-block__content .description div > span:first-child {
          padding-left: 0; }
      .module-block__content .description .rating-container {
        display: inline-block; }
    .module-block__content img {
      width: 100%;
      max-width: 8rem;
      height: auto;
      max-height: 8rem; }
  .module-block__title {
    display: none;
    align-items: center;
    border-bottom: 1px solid #e6e6e6; }
    .module-block__title--mobile {
      display: flex;
      margin-left: 1.2rem;
      flex: 1 1; }
    .module-block__title div {
      display: flex;
      align-items: center;
      padding: 1.1rem 0 1rem; }
    .module-block__title h3 {
      margin: 0; }
    .module-block__title .verified {
      margin-left: 1rem; }
  .module-block .download {
    width: 100%; }
    .module-block .download .btn {
      width: 100%; }
  .module-block__categories {
    display: inline-block; }
    .module-block__categories svg {
      margin-right: 0.6rem; }
  .module-block p {
    margin: 0;
    color: #323232; }

@media only screen and (min-width: 500px) {
  .module-block {
    flex-direction: row; }
    .module-block__icon {
      display: block;
      width: 4.8rem; }
      .module-block__icon img {
        width: 100%; }
    .module-block__title {
      display: flex; }
      .module-block__title--mobile {
        display: none; }
    .module-block__content {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      padding: 0 4.8rem 0 1.8rem; } }

@media only screen and (min-width: 600px) {
  .module-block__icon {
    width: 6rem; }
  .module-block__content > div:first-child {
    width: 100%; }
  .module-block__content .download {
    width: auto; }
  .module-list__categories {
    padding: 11rem 2.4rem 0 0; }
  .module-list .two-col {
    display: flex;
    flex-direction: row; }
    .module-list .two-col > div {
      flex: 1 1; }
      .module-list .two-col > div:first-child {
        flex: 0 0 25%; }
  .module-list__sort {
    justify-content: flex-end; }
    .module-list__sort select {
      padding-left: 1.2rem; } }

@media only screen and (min-width: 768px) {
  .module-block .description__bottom {
    display: flex; }
    .module-block .description__bottom > div:first-child {
      flex: 1 1;
      align-self: center; }
    .module-block .description__bottom > div:last-child {
      margin-top: 0; } }

@media only screen and (min-width: 1024px) {
  .module-block__content {
    flex-wrap: nowrap; }
    .module-block__content > div:first-child {
      flex: 1 1; }
    .module-block__content .download {
      margin-top: 0;
      margin-left: 2.4rem; } }

.module-details .two-col {
  padding-bottom: 4.8rem; }

.module-details__long-desc {
  padding-top: 4.8rem; }

.module-details .details {
  flex: 1 1 auto;
  padding: 0 0 2.4rem 0; }
  .module-details .details > div:first-child {
    display: flex;
    align-items: center; }
  .module-details .details__icon {
    margin-right: 1.2rem;
    max-width: 3.2rem;
    height: auto; }
  .module-details .details p {
    margin: 0.4rem 0;
    font-size: 1.4rem; }

.module-details .download {
  margin-top: 2.4rem; }

@media only screen and (min-width: 768px) {
  .module-details .details {
    padding: 0 4.8rem 0 0; } }

.comments .container,
.comment-form .container {
  max-width: 76.8rem; }

.comments .container {
  padding-top: 0; }

.comments ul {
  padding: 0; }
  .comments ul li {
    display: block; }

.comment {
  position: relative;
  display: flex;
  margin: 1.2rem 0; }
  .comment__author {
    margin-right: 2rem; }
  .comment__content {
    position: relative;
    background-color: #f6f6f6;
    flex: 1 1;
    padding: 2.4rem; }
    .comment__content:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: -1.5rem;
      border-style: solid;
      border-width: 0 1.6rem 1.6rem 0;
      border-color: transparent #f6f6f6 transparent transparent; }
    .comment__content > div:first-child > *:first-child {
      margin-top: 0; }
    .comment__content span {
      display: block;
      color: #939393;
      font-size: 1.2rem; }
  .comment--reply {
    margin-left: 7.2rem; }

.comment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -4.8rem;
  background-color: #f6f6f6; }
  .comment-form .container {
    padding: 7.2rem; }
  .comment-form label {
    display: block; }
  .comment-form input,
  .comment-form textarea {
    width: 100%; }
  .comment-form .btn {
    float: right;
    width: auto;
    margin-bottom: 0; }
  .comment-form form {
    padding-top: 2.4rem; }
  .comment-form__success {
    color: #009933; }
  .comment-form__error {
    color: #a81a4c; }

.star--empty {
  color: #cccccc; }

.star--personal {
  color: #002d72; }

.star--full {
  color: #c99700; }

@media only screen and (min-width: 768px) {
  .comment-form .two-col div:first-child {
    padding-right: 0.6rem; }
  .comment-form .two-col div:last-child {
    padding-left: 0.6rem; } }

.media--gallery {
  margin: 4.8rem 0; }
  .media--gallery .slick-list div {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 25rem; }
  .media--gallery .slick-prev:before,
  .media--gallery .slick-next:before {
    color: black; }
  .media--gallery .slick-slide img {
    max-width: 100%;
    max-height: 25rem; }
  .media--gallery .slick-slide div {
    position: relative; }
    .media--gallery .slick-slide div:hover .expand-container {
      opacity: 1;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out; }
  .media--gallery .slick-slide .expand-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background: rgba(147, 147, 147, 0.5);
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer; }
  .media--gallery .slick-slide .expand-icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 1rem;
    color: #ffffff; }

.media--video .video__youtube iframe {
  width: 100%; }

.media--video .video__upload {
  outline: 0; }

@media only screen and (min-width: 768px) {
  .media--gallery {
    flex: 0 0 50% !important;
    margin: 0; }
  .media--video {
    flex: 0 0 65% !important; } }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 22px 0; }

.pagination > li {
  display: inline; }

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #939393;
  background-color: #fff;
  margin-left: -1px; }

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #323232;
  background-color: #eeeeee; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #323232;
  font-weight: bold;
  cursor: pointer; }

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  cursor: not-allowed; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 20px;
  line-height: 1.3333333; }

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5; }

