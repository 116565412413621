$cw-navy: #002d72;
$cw-gold: #c99700;
$cw-blue: #0085ad;

$success: #009933;
$error: #a81a4c;

$grey-1: #f6f6f6;
$grey-2: #cccccc;
$grey-3: #939393;
$grey-4: #5c5e5f;
$grey-5: #323232;

$mobile: '500px';
$tablet-sm: '600px';
$tablet-lg: '768px';
$desktop-sm: '1024px';
$desktop-lg: '1200px';

$font-lato: 'Lato', sans-serif;
// $font-oswald: 'Lato', sans-serif;

