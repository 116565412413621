* {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
}

html,
body, 
body > div {
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-lato;
    font-weight: 400;
    color: $grey-5;
}

a {
    text-decoration: none;
}

h1 {
    font-size: 3.2rem;
    font-weight: 300;
    color: $grey-4;
}

h2 {
    margin: 0 0 2.4rem;
    font-size: 2rem;
    font-weight: bold;
}

h3 {
    font-size: 1.5rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $grey-5;
}

hr {
    margin: 2.4rem -2.4rem;
    border: 0;
    border-bottom: 2px solid $grey-1;
}

select,
input,
textarea {
    font-family: $font-lato;
    font-size: 1.6rem;

    &:focus {
        outline: none;
    }
}

input,
textarea {
    display: block;
    width: 100%;
    margin-bottom: 1.2rem;
    padding: 1.2rem;
    border: 1px solid #e6e6e6;
}

label {
    padding-bottom: 0.8rem;
}

header {
    position: relative;
    box-shadow: 5px 5px 15px -5px $grey-2;
    z-index: 1;
    max-height: 10rem;

    & > div {
        margin: auto;
        padding: 1.2rem 2.4rem;
        width: 100%;
        max-width: 120rem;

        div {
            display: inline-block;
        }
    }

    img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 7.2rem;
    }
}

main {
    position: relative;
    min-height: calc(100% - 10rem);
    margin-bottom: -4.2rem; // neg height of footer
    // padding-bottom: 7.2rem;
}

footer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 4.2rem;
    padding: 1rem;
    background-color: $grey-1;
    font-size: 1.2rem;
}

svg {
    color: $grey-2;
}

.container {
    position: relative;
    display: block;
    margin: auto;
    padding: 2.4rem;
    width: 100%;
    max-width: 120rem;
}

section {
    padding: 2.4rem 4.8rem;

    .overview {
        padding-bottom: 4.8rem;
    }
}

.verified {
    margin: 0 0.6rem;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.btn {
    display: inline-block;
    margin-bottom: 1.2rem;
    padding: 1.2rem 2.4rem;
    width: auto;
    background-color: $cw-navy;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: #002967;
    }

    &:focus {
        background-color: #00245b;
    }

    &--2 {
        background-color: $grey-1;
        color: $grey-5;

        &:hover {
            background-color: #f2f2f2;
        }
    
        &:focus {
            background-color: #e4e4e4;
        }
    }
}

.two-col {
    display: block;
}

.idealab {
    position: relative;
}

@media only screen and (min-width : $tablet-lg) {

    .container {
        padding: 4.8rem 10.8rem;
    }

    .two-col {
        display: flex;
        flex-direction: row;
    
        & > div {
            flex: 1;
        }
    } 
}